.mdxeditor, .plan-editor {
  width: 100%;
  max-height: 700px;
  overflow: auto;
}

.plan-editor {
  padding: 2rem !important;
}

.plan-editor h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor h3 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor h4 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor h5 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.plan-editor blockquote {
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  border-left: 4px solid #ccc;
  color: #666;
  font-style: italic;
  background-color: #f9f9f9;
}

.plan-editor ul,
.plan-editor ol {
  padding-left: 2rem;
  margin: 1rem 0;
}

.plan-editor ul li,
.plan-editor ol li {
  margin-bottom: 0.5rem;
}

.plan-editor ul ul,
.plan-editor ol ol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}